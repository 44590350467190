/* globals require */
/* jshint node:true */

'use strict';

$(document).ready(function () {
  // Foundation related scripts
  require('./modules/foundation');

  // Instafeed and data-binding
  require('./modules/instafeed');

  // Fixed header scroll
  require('./modules/header-scroll');

  // Sliders
  require('./modules/carousel/main');

  // Navigation
  require('./modules/navigation');

  // Map
  require('./modules/map');

  // Image gallery - see Our Farmers
  require('./modules/image-gallery');

  // Newsletter
  require('./modules/newsletter');
});

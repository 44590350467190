/* globals Foundation */

'use strict';

/**
 * Manages the fixed header scrolling on the homepage
 * @type {Object}
 */
var HeaderScroll = {
  $topBar: $('.js-header-topbar'),
  $nav: $('.js-header-nav'),
  $headerFiller: null,
  $window: $(window),
  positions: {},
  hidden: 'is-hidden',
  fixed: 'is-fixed',
  started: false,

  /**
   * Kick things off
   */
  init: function() {
    this.addFillerElement();
    this.calculatePositions();
    this.bindUI();

    this.started = true;

    this.$window.trigger('scroll');
  },

  /**
   * This inserts an empty div that will occupy the space
   * that is left behind when header becomes fixed
   */
  addFillerElement: function() {
    var _ = this;

    _.$headerFiller = $('<div/>', {
      'class': 'js-header-filler is-hidden'
    }).insertBefore(_.$nav);
  },

  /**
   * Calculate when to make the header fixed
   */
  calculatePositions: function() {
    var _ = this;

    _.positions = {
      nav: {
        offsetTop: _.$nav.offset().top - _.$topBar.height()
      }
    };

    _.$headerFiller.css('height', _.$nav.outerHeight());
  },

  /**
   * Watch the window for changes to scroll and resize
   */
  bindUI: function() {
    var _ = this;

    _.$window.on('scroll', function() {
      var $this = $(this),
          scrollTop = $this.scrollTop();

      if (scrollTop >= _.positions.nav.offsetTop) {
        // Make nav fixed
        _.$headerFiller.removeClass(_.hidden);

        if (! Foundation.utils.is_small_only()) {
          _.$nav.addClass(_.fixed).css('top', _.$topBar.height());
        } else {
          _.$nav.css('top', 0);
        }
      } else {
        // Un-fix it
        _.$headerFiller.addClass(_.hidden);
        _.$nav.removeClass(_.fixed).css('top', 0);
      }
    });

    // Recalculate the required offset in case the window resizes
    _.$window.on('resize', Foundation.utils.debounce(function() {
      _.calculatePositions();
      _.$window.trigger('scroll');
    }));
  }
};

Foundation.utils.image_loaded($('body'), function() {
  if (Foundation.utils.is_large_up() && HeaderScroll.$nav.length && ! HeaderScroll.started) {
    HeaderScroll.init();
  }

  $(window).on('resize', Foundation.utils.debounce(function() { 
    if (! HeaderScroll.started && Foundation.utils.is_large_up()) {
      HeaderScroll.init();
    }
  }));
});

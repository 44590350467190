
'use strict';

var sliders = require('./list');

var CarouselController = {
  /**
   * Array of sliders
   * @type {Array}
   */
  sliders: sliders,

  /**
   * Loop through the sliders array and start each slider
   */
  init: function() {
    this.sliders.forEach(this.startSlider);
  },

  /**
   * Recieves a slider object and initialises it
   *
   * @param {Object} slider - contains selector (el) and options (opts)
   */
  startSlider: function(slider) {
      $(slider.el).slick(slider.options);
  }
};

CarouselController.init();

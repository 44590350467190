'use strict';

var prev = '<button class="slick-prev c-icon icon-arrow-left"><span class=show-for-sr>Previous</span></button>',
    next = '<button class="slick-next c-icon icon-arrow-right"><span class=show-for-sr>Next</span></button>';

module.exports =  [
  {
    el: '.js-profile-slider',
    options: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      prevArrow: prev,
      nextArrow: next
    }
  },

  {
    el: '.js-product-slider',
    options: {
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: true,
      prevArrow: prev,
      nextArrow: next,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },

        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    }
  },

  {
    el: '.js-product-main-gallery',
    options: {
      asNavFor: '.js-product-sub-gallery',
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    }
  },

  {
    el: '.js-product-sub-gallery',
    options: {
      asNavFor: '.js-product-main-gallery',
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: true,
      focusOnSelect: true,
      arrows: false
    }
  },

  {
    el: '.js-product-slider-homepage',
    options: {
      autoplay: true,
      slidesToShow: 5,
      slidesToScroll: 2,
      prevArrow: prev,
      nextArrow: next,

      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    }
  }
];

/* jshint node: true */
/* globals Instafeed */


'use strict';

var instafeed = new Instafeed({
  limit: '12',
  get: 'user',
  sortBy: 'most-recent',
  userId: 1409531969,
  accessToken: '30709064.4adb014.6b7d98ec39f640b49030a6c59b577203',
  clientId: '4adb0141ed92499bb5c888a3f8f42c75',
  template: '<li class="small-6 medium-4 columns u-mb-15"><a href="{{link}}" target="_blank"><img src="{{image}}" alt="{{caption}}"></a></li>',
  error: function(data) {
    console.warn(data);
  }
});

// instafeed.run();
/*globals $, Foundation*/
/*jshint node:true*/

'use strict';

var NavigationController = {
  $nav: $('.c-nav--main'),

  init: function() {
    this.$nav.mmenu({
      extensions: ['theme-dark']
    }, {
      clone: true
    });
  }
};

NavigationController.init();
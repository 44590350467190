/* jshint node:true */
/* globals Vue, PhotoSwipe, PhotoSwipeUI_Default */
/* exported Gallery */

'use strict';

if ($('.js-image-gallery').length) {
  var imageGallery;

  var Gallery = new Vue({
    el: '.js-image-gallery',

    data: {
      images: window.LMC.Gallery
    },

    methods: {
      // Trigger onclick and return the element that was clicked to set the index
      startGallery: function(e) {
        var _ = this,
            pswpElement = document.querySelectorAll('.pswp')[0],
            options = {
              // Starts the gallery on the clicked index
              index: e.$data.id - 1 // it's using {count} so it's not zero-based - just subtract 1
            };

        imageGallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _.images, options);
        imageGallery.init();
      }
    }
  });
}

/* jshint node:true*/
/* globals google, $, Vue, MarkerClusterer*/
/* exported */

'use strict';

/**
 * This must be attached to the window in order to support a callback
 * for when the API has been loaded
 */
var MapController = window.MapController = {
  el: '',
  container: $('#map'),
  options: {
    zoom: 7,
    center: {lat: 53.000, lng: -8.000},
    scrollwheel: false
  },
  // Probably need to get this from a window object from EE Map plugin
  markers: window.StoreLocations,

  init: function() {
    var _ = this;

    _.loadApiScript();
  },

  /**
   * Async loading of map api
   */
  loadApiScript: function() {
    var script = document.createElement('script');

    script.type = 'text/javascript';

    script.src = 'https://maps.googleapis.com/maps/api/js?v=3' +
      '&signed_in=false&callback=MapController.start';

    document.body.appendChild(script);
  },

  /**
   * Called once map api is ready
   */
  start: function() {
    var _ = MapController;

    _.el = new google.maps.Map(document.getElementById('map'), _.options);

    _.addMarkers();
  },

  /**
   * Add markers to map
   */
  addMarkers: function() {
    var _ = this,
        markerArray = [];

    // Iterate over and add markers to map
    _.markers.forEach(function(item) {
      var latLng = new google.maps.LatLng(item.lat, item.long);

      var marker = new google.maps.Marker({
        position: latLng,
        title: item.title
      });

      var infowindow = new google.maps.InfoWindow({
        content: item.title
      });

      google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(_.el, marker);
      });

      markerArray.push(marker);
    });

    new MarkerClusterer(_.el, markerArray, {});
  }
};

if (MapController.container.length) {
  MapController.init();

  new Vue({
    el: '.js-map-locations',
    data: {
      locations: window.StoreLocations,
      pageNumber: 0,
      searchText: '',
      totalPages: 0
    },

    methods: {
      /**
       * Helper method to scroll to the map
       */
      scrollToMap: function() {
        $(window).scrollTop($('#map').offset().top);
      },

      /**
       * Helper method to scroll to results
       */
      scrollToResults: function() {
        $(window).scrollTop($('.c-form__results').offset().top - 200);
      },

      /**
       * Give the searchText a value of Ireland
       */
      getLocations: function(type) {
        this.searchText = type;
        this.scrollToResults();
      },

      /**
       * Reset the search query
       */
      reset: function() {
        var latLng = new google.maps.LatLng(53.000, -8.000);

        this.searchText = '';

        MapController.el.setCenter(latLng);
        MapController.el.setZoom(7);
      },

      /**
       * Show the clicked location and zoom in on the map
       */
      showStore: function(location) {
        var item = location.$data.item,
            latLng = new google.maps.LatLng(item.lat, item.long);

        MapController.el.setZoom(14);
        MapController.el.setCenter(latLng);

        this.scrollToMap();
      },

      /**
       * Go to a given page
       */
      goToPage: function(item) {
        this.pageNumber = item.$data.page;
      },

      /**
       * Previous page
       */
      previousPage: function() {
        if (this.pageNumber === 0) {
          return;
        }

        this.pageNumber--;
      },

      /**
       * Next page
       */
      nextPage: function() {
        if (this.pageNumber === this.totalPages - 1) {
          return;
        }

        this.pageNumber++;
      }
    },

    filters: {
      /**
       * Paginate the list
       */
      paginate: function(list) {
        var index = this.pageNumber * 10;
        this.totalPages = Math.ceil(this.locations.length / 10);
        return list.slice(index, index + 10);
      },

      /**
       * Only show a limited number of pages
       */
      limitPagination: function(list) {
        if (this.pageNumber < 4) {
          return list.slice(this.pageNumber, this.pageNumber + 6);
        }

        return list.slice(this.pageNumber - 3, this.pageNumber + 3);
      }
    }
  });
}

'use strict';

var NewsLetter = {
  $showButton: $('.js-show-form'),
  $form: $('.js-signup-form'),

  init: function() {
    this.$showButton.on('click', this.showForm);
  },

  showForm: function(e) {
    var _ = NewsLetter;

    e.preventDefault();

    _.$form.toggleClass('is-visible');
    _.$showButton.css('display', 'none');
  }
};

NewsLetter.init();
